.products {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    /* Hide overflow to prevent content from spilling outside */
  }

  /* .producttext {
    font-family: 'Oswald', sans-serif;
  } */

  .backgroundImg {
    height: 100%;
    width: 100%;
     /* Position the background image behind the content */
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0; /* Positioned behind leftSide and rightSide */
    position: fixed;
  }

  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* White overlay with 50% transparency */
    z-index: 1; /* Ensures overlay is on top of the image */
  }

  .products .container {
    padding-top: 75px;
    height: 100%;
  }

  .products .container h2{
    z-index: 2;
  }

  .products .container .projects{
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    padding-bottom: 60px;
  }

  .product {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #ffff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in;
  }

  /* .project {
    display: flex;
    flex-direction: column;
     overflow: hidden;
  } */

  .products .container,
.products .container .projects {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}


  .product:hover {
    transform: scale(1.05); /* Scale up the product */
    cursor: pointer;
  }

  .product div {
    width: 100%;
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .product h4{
    margin: 10px 0 0 20px;

  }

  .product p {
    margin: 10px 20px 10px 20px;
    font-size: 12px;
    text-align: justify;
    font-weight: 500;

  }

  @media (max-width: 1024px) {
    .products .container .projects {
      grid-template-columns: repeat(2, 1fr); /* 2 products per row */
    }
  }

  @media (max-width: 768px) {
    .products .container .projects {
      grid-template-columns: 1fr; /* 1 product per row */
    }
  }
