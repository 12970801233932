.about {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: row;
    margin-top: 100px;
  }

  .backgroundImg {
    height: 100%;
    width: 100%;
    position: fixed; /* Position the background image behind the content */
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0; /* Positioned behind leftSide and rightSide */
  }

  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* White overlay with 50% transparency */
    z-index: 1; /* Ensures overlay is on top of the image */
  }

  .about .container {
    padding-top: 75px;
    height: 100%;
    justify-content: flex-start;
  }

  .aboutTop{
    width: 100%;
    display: flex;
    z-index: 2;
    flex-direction: column;
    padding-bottom: 40px;
  }

  .aboutBottom{
    width: 100%;
    display: flex;
    z-index: 2;
    flex-direction: column;
    padding-bottom: 70px;
  }

  .aboutTop h2,
  .aboutBottom h2{
    padding-bottom: 5px;
  }

  .reasons {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 3 columns */
    gap: 20px; /* Adjust the gap between the grid items */
    height: 100%;
  }

  .services {
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: transform 0.3s ease-in;
  }

  .services:hover{
    transform: scale(1.03); /* Scale up the product */
    cursor: pointer;
  }

  .reason {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 0.3s ease-in;
  }

  .reason:hover{
    transform: scale(1.05); /* Scale up the product */
    cursor: pointer;
  }

  .reason .heading,
  .services .heading {
    height: 30px;
    padding: 5px 0;
    width: 100%; /* Adjusted to be responsive */
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
  }

  .reason .heading h4,
  .services .heading h4{
    padding-left: 20px;
  }

  .reason .description {
    height: 100px;
    width: 100%; /* Adjusted to be responsive */
    background-color: #ffff;
  }

  .services .description{
    height: 100%;
    width: 100%;
    background-color: #ffff;
  }

  .reason .description p,
  .services .description p{
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: justify;

  }

  /* Media Queries for Responsive Design */
@media (max-width: 1490px) {
  .reasons {
      grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .reasons {
      grid-template-columns: repeat(2, 1fr);
  }

}

@media (max-width: 768px) {
  .reasons {
      grid-template-columns: 1fr; /* 1 column on mobile */
  }

}
