.navbar {
    width: 100%;
    height: 100px;
    background: linear-gradient(53deg, rgba(1,0,13,1) 0%, rgba(43,0,0,1) 56%, rgba(80,0,0,1) 83%);
    display: flex;
    flex-direction: row;
    position: fixed; /* Fix the navbar to the top */
  top: 0;          /* Align it to the top of the page */
  left: 0;
    z-index: 10; /* Ensures the navbar stays above other content */

  }

  .navbar .leftSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .navbar .leftSide .logobg {
    background-color: #ffff;
    height: 125px;
    width: 180px;
    clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  }

  .navbar .leftSide img {
    width: 120px;
    padding-right: 10px;
  }

  .navbar .leftSide .brandname {
    color: white;
    padding-left: 40px;
    /* font-weight: bold; */
  }



  .navbar .rightSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 50px;
  }

  .navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    transition: transform 0.2s ease;
  }

  .navbar a:hover {
    transform: scale(1.07);
  }

  .navbar a.active {
    font-weight: bold;
  }

  .navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }

  .navbar .rightSide svg {
    font-size: 35px;
  }

  /* Hidden links slide-in panel */
  .navbar .hiddenLinks {
    position: fixed;
    top: 0;
    right: -100%; /* Initially hidden off-screen */
    width: 85%;
    /*max-width: 300px;*/
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    transition: right 0.3s ease-in-out;
    z-index: 9; /* Below the navbar */
  }

  .navbar .hiddenLinks a {
    padding: 15px 20px;
    color: rgb(88, 49, 4);
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.2s ease;
  }

  /* Hover effect for links */
.navbar .hiddenLinks a:hover {
  transform: scale(1.05); /* Slight scale effect */
  color: darkbrown; /* Darker shade of brown on hover */
}

  .navbar #open .hiddenLinks {
    right: 0; /* Slide in when open */
  }

  .navbar #open button{
    background-color: transparent;
    border: none;
    color: rgb(88, 49, 4);
    cursor: pointer;
    text-align: end;
    padding-right: 60px;
    padding-bottom: 50px;
  }

  .navbar #close .hiddenLinks {
    right: -100%; /* Slide out when closed */
  }

  .navbar .blurredBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px); /* Apply blur to the rest of the page */
    background-color: rgba(0, 0, 0, 0.3); /* Optional dimming effect */
    z-index: 8; /* Below hiddenLinks panel but above the content */
    pointer-events: none; /* Prevent interactions with the blurred layer */
}

  @media only screen and (max-width: 900px) {
    .navbar .rightSide a {
      display: none;
    }

    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 50px;
    }

    .navbar .leftSide .brandname{
      display: none;
    }

    .navbar .rightSide button {
      display: inherit;
    }
  }

  @media only screen and (min-width: 900px) {
    .navbar .rightSide button {
      display: none;
    }

    .hiddenLinks {
      display: none;
    }
  }
