@import "@fontsource/oswald/300.css";


.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}

body{
  padding: 0;
  margin: 0;
}

.container{
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  flex-direction: column;
}

.loadingLogo {
  width: 100px;
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
