.contact {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: row; /* Ensures leftSide and rightSide are side by side */
    align-items: center;
    margin-top: 100px;
  }

  .contact .container .contactDetails{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For all browsers */
input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

/* For Webkit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.contact .backgroundimg {
    height: 100%;
    width: 100%;
    position: fixed; /* Position the background image behind the content */
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0; /* Positioned behind leftSide and rightSide */
  }

  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* White overlay with 50% transparency */
    backdrop-filter: blur(10px);
    z-index: 1; /* Ensures overlay is on top of the image */
  }

 .contact .container .leftSide {
    height: 100%;
    flex: 50%; /* Takes up half the width of the contact container */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Positioned above the background */
    /*position: relative;*/  /* Keeps it above the overlay */
  }

  .contact .container .rightSide {
    height: 100%;
    flex: 50%; /* Takes up the other half of the contact container */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Positioned above the background */
    /*position: relative;*/ /* Keeps it above the overlay */
  }

  .rightSide hr{
    background-color: black;
    border: none;
    height: 2px;
  }


  .rightSide .contactInfo1{
    display: flex;
    align-items: center;
  }

  .socialIcons svg {
    transition: transform 0.2s ease; /* Smooth transition for resizing */
    cursor: pointer; /* Optional: Adds a pointer cursor on hover */
    color: black;
  }

  .socialIcons svg:hover {
    transform: scale(0.9); /* Scales down the icon size by 10% */
  }

  .contact .leftSide h3{

    z-index: 2;
    position: relative;
    padding-bottom: 0;
  }

  .contact .rightSide p{
    font-size: 14px;
    font-weight: 400;
  }

  .rightSide .contactInfo1 p,
  .rightSide .contactInfo2 h5{
    font-weight: 600;

  }

  .emailLink {
    color: black; /* Change the text color to black */
    text-decoration: none; /* Remove the underline */
  }

  .contact .rightSide h3 {

    z-index: 2;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;
    /*padding-left: 30px;*/
  }

  form {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    /*padding: 30px;*/
    z-index: 2;
  }

  form input {
    height: 30px;
    width: 350px;
    border: none;
    color: black;
  }

  form textarea {
    margin-top: 15px;
    height: 70px;
    width: 350px;
    border: none;
    color: black;
  }

  form input:focus,
  textarea:focus {
    outline: none;
  }

  form label {
    margin-top: 15px;
    color: black;
    font-weight: 500;

  }

  form button {
    margin-top: 40px;
    width: 140px;
    height: 40px;
    border: none;
    background-color: #121619;
    color: whitesmoke;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease, border 0.2s ease;
  }

  /* SweetAlert2 Custom Popup */
.swal-popup {
  font-family: 'Oswald', sans-serif;

}


  form button:hover{
    background-color: whitesmoke;
    color: black;
    border: 2px solid black;
    transform: scale(0.95);
  }

  @media only screen and (max-width: 900px) {
    .contact .container .contactDetails {
        display: flex;
        flex-direction: column;
        padding-top: 75px;
    }

    .contactDetails .leftSide{
      width: 100%;
    }

    .contactDetails .leftSide form input,
    .contactDetails .leftSide form textarea {
      width: 275px;
    }


    .contactDetails .rightSide{
      margin: 40px 0;
    }
  }
