.footer {
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(53deg, rgba(1,0,13,1) 0%, rgba(43,0,0,1) 56%, rgba(80,0,0,1) 83%);
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  }
  .footer .container div{
    margin: 0 25px;
  }

  .container .footerContent{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    z-index: 3;
    width: 100%;
    /* gap: 10px; */
    color: white;
    padding: 20px 0;
    display:grid;
    justify-content: center;
  }

 

  .footerContent h4{
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-size: 1.125rem;
  }

  .footerAbout{
    text-align: justify;
 
  }

  .container .footerContent .footerAbout div,
  .container .footerContent .contactDetails div,
  .container .footerContent .workingHours div{
    margin-right: 0;
    margin-left: 0;
  }
  /* .footerAboutSection{
    display: flex;

    justify-content: space-between;
  } */

  .footerContent .links{
    align-items: center;

  }

  .footerContent .links h4{
    display: flex;
    justify-content: center;
  }


  .footerContent .links div a{
    margin-top: 0;
    margin-bottom: 15px;
    justify-content: center;
    display: flex;
  text-decoration: none;
  color: white;
  }

  .footerContent .links div a:hover{
    cursor: pointer;
  color: white;
  }

  /* .footerContent .contactDetails{
    width: 25%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
  } */

  .footerContent .contactDetails div h4{
    margin-bottom: 0;
    margin-top: 0;
    font-weight: lighter;
    font-size: 16px;
  }

  .footerContent .contactDetails div p{
    margin-top: 5px;
  }


  /* .footerContent  .workingHours{
    width: 25%;
    padding-left: 10px;
  } */

  .footerContent  .workingHours div p{
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 5px;
  }

  .footerContent .social svg{
    cursor: pointer;
    font-size: 30px;
    transition: transform 0.2s ease;
    color: white;
  }

  .footerContent .social svg:hover {
    transform: scale(0.9);
  }

  .footerAbout .logobg img{
    height: 90px;
    width: 60px;
    padding-left: 0;
  }

 /* Copyright Section */
/* Copyright Section */
.footer .container .copyright {
  background-color: #000000; /* Dark background for copyright */
  color: white; /* White text color */
  padding: 10px 0; /* Padding for copyright */
  margin-top: 20px; /* Space above the copyright section */
  width: 100%; /* Full width */
  text-align: center; /* Center align the copyright text */
  font-size: 14px; /* Font size for copyright */
}


.copyright p {
  margin: 0; /* Remove margin from copyright text */
  font-size: 14px; /* Font size for copyright */
  text-align: center; /* Center align the copyright text */
}

 .copyright {
  grid-column: span 4; /* Span the entire grid width if using grid */
}

  @media (max-width: 900px) {
    .container .footerContent {
      grid-template-columns: 1fr; /* 1 product per row */
    }

    .container .footerContent .footerAbout,
    .footerContent .links,
    .footerContent .contactDetails,
    .footerContent .workingHours,
    .footerContent .footerAbout{
      padding: 0 20px;
    }

    .footer .container div{
      margin: 0;
    }

    .links {
      text-align: left; /* Aligns text to the left */
      margin-left: 0 !important;
    }

    .links div {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Ensures links are aligned to the left */
      gap: 3px; /* Adds spacing between links */
    }

    .copyright{
      padding-left: 0;
      text-align: center; 
    }

    .copyright p {
      margin: 0 !important; /* Remove margin from copyright text */
      font-size: 12px; /* Font size for copyright */
      text-align: center; /* Center align the copyright text */
    }
  }

  @media (max-width: 390px) {
    .footerContent .footerAbout h4,
    .footerContent .contactDetails h4,
    .footerContent .workingHours h4,
    .footerContent .footerAbout h4{
      font-size: 0.90rem;
    }

    .links {
      display: none; /* Hide Links section in mobile view */
    }

    .footerAboutSection .logobg img{
      height: 75px;
    width: 50px;
    }

    .footerContent p{
      font-size: 0.80rem;
    }

    .footerContent .contactDetails div h4{
      font-size: 0.80rem;
    }
  }