@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.home{
    width: 100%;
    max-height: 100vh; 
    /* font-family: 'Oswald', sans-serif; */
    margin-top: 100px;
}

/* .loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  flex-direction: column;
}

.loadingLogo {
  width: 100px;
  margin-top: 20px;
} */

/* .spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */


.headerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

.headerContainer .leftSide{
    width: 40%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: left;
    position: relative;
    background-size: cover;
    background-position: center;
}

.imgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% transparency */
    z-index: 1; /* Ensures overlay is on top of the image */
  }

.leftSide .heading{
    margin: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.leftSide .heading .main{
 font-size: large;
}

.leftSide .heading .main .sub1{
  font-weight: normal;
  font-size: large;
 }

/* .leftSide .heading p{
  font-weight: normal;
 } */

.leftSide .heading div p{

    position: relative;
    z-index: 2;

    color: white;
    /* text-align: justify; */
    margin-bottom: 0;
}

.headerContainer h5{
    font-size: 2.5rem;
    position: relative;
    z-index: 2; /* Ensures text is on top of the overlay */
    padding-bottom: 0;
    margin-top: 0;
    color: white;
}

/* .headerContainer p {
    font-size: 20px;
    position: relative;
    z-index: 2;
    font-weight: lighter;
    color: white;
    text-align: justify;
  } */

  .headerContainer .rightSide{
    width: 60%;
    height: 60vh;
  }

  /* .headerContainer .rightSide .imageContainer {
    width: 100%;
    height: auto;
  } */
  .imageContainer{
    height: auto;
    width: 100%;
  }



  /* Style for the slider images */
  .slider-image {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    position: relative;
    background-size: cover;
    background-position: center;
  }

  /* Adjust slider dots and arrows if needed */
  .slick-dots {
  bottom: 10px;
  }

  .slick-dots li button:before {
    font-size: 14px; /* Adjust this value to increase the size of the dots */
    color: white; /* Change the color if needed */
  }

  .slick-dots li.slick-active button:before {
    font-size: 14px; /* Adjust this value to increase the size of the dots */
    color: white;
  }

  /* Optional: Adjust the spacing between the dots */
  .slick-dots li {
    margin: 0 6px; /* Adjust margin to control dot spacing */
  }



  .slick-slider,
  .slick-list {
      margin: 0 !important; /* Remove any bottom margin from the slider */
      padding: 0 !important; /* Remove any padding */
  }


  /* .finishes{
    width: 100%;
    height: 40vh;
    background-color: #e6e6e6;
    position: relative;
    display: block;
    justify-content: center;
    margin: 0;
    padding: 0;
  } */

  .finishesHeading{
    text-align: center;
    color:rgb(124, 124, 124);
    padding-top: 10px;
  }

  /* .logoSliderContainer{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 5px;
    padding: 20px 10px;
  } */

  /* .companies .logo{
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  .companies .logo img{
    width: 100px;
    padding: 10px  0;
  } */

  /* Finishes Section */
.finishes {
  width: 100%;
  max-height: 40vh;
  padding: 10px 0 80px;
  /* background-color: #f7f7f7; */
  text-align: center;
}

/* .finishesHeading h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
} */

.logoSliderContainer {
  max-width: 100%;
  margin: 0 auto;
}

.logo {
  padding: 30px 0 10px 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.logo img {
  max-width: 100%;
  max-height: 55px;
  object-fit: contain;
}

  .logoSliderContainer .logo img:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
  }



  @media only screen and (max-width: 1072px) {
  .headerContainer {
    flex-direction: column;
  }

  .headerContainer .leftSide {
    width: 100%;
    height: auto;
  }

  .headerContainer .rightSide{
    width: 100%;
    height: auto;
  }

  .imageContainer .slider-image {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    position: relative;
    }


  .imageContainer {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    max-height: 35vh;
  }

  .finishes {
    margin: 0 !important;
    padding: 0 !important;
    height: auto;
    width: 100%;
  }
    .logoSliderContainer {
      grid-template-columns: repeat(5, 1fr); /* Stack logos in a single column */
      gap: 5px; /* Adjust gap as needed */
      padding-bottom: 60px;
    }

    .logoSliderContainer .logo {
      padding: 30px 0 10px 0;
      width: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 912px) {

    .finishes {
      height: auto;
      width: 100%;
    }


    /* Remove margin and padding from the active slider image */
  /* .slick-active .slider-image {
    margin: 0 !important;
    padding: 0 !important;
  } */

  /* Optionally, remove margin and padding from all slider images */
  .slider-image {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    max-height: 30vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .headerContainer {
    flex-direction: column;
  }

  .headerContainer .leftSide {
    width: 100%;
    height: auto;
  }

  .headerContainer .rightSide{
    width: 100%;
    max-height: 30vh;
  }

  /* .imageContainer .slider-image {
    width: 100%;
    max-height: 30vh;
    display: flex;
      justify-content: center;
      position: relative;
      background-size: cover;
      background-position: center;
    } */


  .imageContainer {
    width: 100%;
    height: 30vh;
  }

  .logoSliderContainer {
    display: block; /* Remove slider */
   
  }

  .logoSliderContainer .logo {
    display: block; /* Stack logos vertically */
    margin-bottom: 20px; /* Add space between logos */
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .logoSliderContainer .logo img {
    width: 120px; /* Adjust logo size */
    height: auto;
    transition: transform 0.3s ease-in-out;
  }

  /* Optionally add hover effect for logos */
  .logoSliderContainer .logo img:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
  }
}



@media (max-width: 768px) {
  .leftSide .heading div p {
    font-size: 1rem; /* Further reduce font size for very small screens */
  }

    /* Remove margin and padding from the active slider image */
  /* .slick-active .slider-image {
    margin: 0 !important;
    padding: 0 !important;
  } */

  /* Optionally, remove margin and padding from all slider images */
  .slider-image {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    max-height: 35vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .headerContainer h1 {
    font-size: 1.9rem; /* Further adjust heading size */
  }

  .headerContainer .rightSide{
    width: 100%;
    max-height: 35vh;
  }

  /* .imageContainer .slider-image {
    width: 100%;
    max-height: 35vh;
    display: flex;
      justify-content: center;
      position: relative;
      background-size: cover;
      background-position: center;
    } */


  .imageContainer {
    width: 100%;
    height: auto;
  }

  .finishes {
    height: auto;
    width: 100%;
    padding: 20px 0 10px;
  }

  .logoSliderContainer {
    grid-template-columns: repeat(1, 1fr); /* Stack logos in a single column */
    gap: 5px; /* Adjust gap as needed */
    padding-bottom: 5px;
    
  }

  .logoSliderContainer .logo {
    width: 100%;

    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}


@media (max-width: 390px) {
  .leftSide .heading div p {
    font-size: 0.7rem; /* Further reduce font size for very small screens */
  }

  /* Remove margin and padding from the active slider image */
  /* .slick-active .slider-image {
    margin: 0 !important;
    padding: 0 !important;
  } */

  /* Optionally, remove margin and padding from all slider images */
  .slider-image {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    max-height: 29vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .headerContainer h1 {
    font-size: 1.9rem; /* Further adjust heading size */
  }

  .headerContainer .rightSide{
    width: 100%;
    max-height: 29vh;
  }

  /* .imageContainer .slider-image {
    width: 100%;
    max-height: 29vh;
    display: flex;
      justify-content: center;
      position: relative;
      background-size: cover;
      background-position: center;
    } */


  .imageContainer {
    width: 100%;
    height: auto;
  }

  .finishesHeading h3 {
    font-size: 0.75rem;
  }

  .finishes {
    height: auto;
    width: 100%;
  }
  
  .logoSliderContainer {
    max-width: 100%;
    margin: 0;
  }
  
  .logo {
    padding: 30px 0 10px 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .logo img {
    max-width: 100%;
    max-height: 50px;
    object-fit: contain;
  }

  /* .finishes {
    height: 100%;
  }

  .logoSliderContainer {
    grid-template-columns: repeat(1, 1fr); 
    gap: 5px; 
    padding-bottom: 60px;
    
  }

  .logoSliderContainer .logo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

}



